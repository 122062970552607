<template>
    <div class="survey__question__confirm">
        <small style="position:relative; top:1px">{{ hintText || $t('fields.confirm.checked') }}</small>
        <a :href="confirmUrl" target="_blank" v-if="confirmUrl" style="float:right">
            <v-icon>info</v-icon>
        </a>
        <v-checkbox
            v-model="confirm"
            :label="confirmText.en"
            :rules="isRequired ? [rules.required] : []"
            name="confirm"
            @change="updateAnswer"
        ></v-checkbox>
    </div>
</template>

<script>
export default {
    name: 'Confirm',
    props: {
        confirmText: {
            type: Object,
            required: true
        },
        confirmUrl: {
            type: String
        },
        isRequired: {
            default: true
        },
        hintText: {
            default: 'fields.confirm.hint_text'
        }
    },
    data() {
        return {
            confirm: false,
            rules: {
                required: value => !!value || this.$t('fields.general.rules.required')
            }
        };
    },
    methods: {
        updateAnswer() {
            this.$emit('updateAnswer', this.confirm);
        }
    },
    created() {
        this.updateAnswer();
    }
};
</script>
